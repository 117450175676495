<template>
  <Layout>
    <PageHeader slot="header" title="Insights">
      <template slot="page-buttons">
        <button
          type="button"
          @click="refreshData()"
          class="tw-mr-2 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-gray-800 tw-bg-gray-100 hover:tw-bg-gray-200 focus:tw-outline-none"
        >
          <icon :icon="['fal', 'sync']" :spin="isRefreshing" fixed-width />
        </button>
        <!-- <button
            type="button"
            class="tw-mr-2 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-gray-800 tw-bg-gray-100 hover:tw-bg-gray-200 focus:tw-outline-none"
          >
            <icon :icon="['fal', 'expand-alt']" fixed-width />
          </button>
          <button
            type="button"
            class="tw-mr-2 tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-rounded-md tw-shadow-sm tw-text-sm tw-font-medium tw-text-gray-800 tw-bg-gray-100 hover:tw-bg-gray-200 focus:tw-outline-none"
          >
            <icon :icon="['fal', 'sliders-h']" fixed-width class="tw-mr-1" />
            Customise
          </button> -->
      </template>
    </PageHeader>

    <div
      class="tw-mb-6 tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2 lg:tw-grid-cols-4"
    >
      <MetricCard
        v-if="!showResolved"
        :loading="isLoading"
        :value="totalIncidents"
        color="red"
        label="Incidents"
      />
      <MetricCard
        v-if="showResolved"
        :loading="isLoading"
        :value="openIncidents"
        color="red"
        label="Open"
      />
      <MetricCard
        v-if="showResolved"
        :loading="isLoading"
        :value="closedIncidents"
        color="green"
        label="Resolved"
      />
      <MetricCard
        :loading="isLoading"
        :value="`${ceRating}%`"
        color="gray"
        label="CE Rating"
      />
    </div>
    <div
      class="tw-mb-6 tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2 lg:tw-grid-cols-3"
    >
      <ChartCard label="Current risk level" :loading="isLoading">
        <slot v-if="!isLoading" name="chart">
          <event-severity-chart
            :severity_trends="severityTrends"
            :height="mobileHeight"
            chartId="chart-line-01"
          />
        </slot>
      </ChartCard>
      <ChartCard label="Realtime activity" :loading="isLoading">
        <slot v-if="!isLoading" name="chart">
          <event-activity-chart
            v-if="!isLoading"
            :activity="activityTrends"
            :height="mobileHeight"
            chartId="chart-line-02"
          />
        </slot>
      </ChartCard>
      <ChartCard label="Reported risk levels" :loading="isLoading">
        <slot v-if="!isLoading" name="chart">
          <event-severity-pie
            v-if="!isLoading"
            :severity="severityCounts"
            :height="mobileHeight"
            chartId="chart-pie-03"
            class="tw-px-2"
          />
        </slot>
      </ChartCard>
    </div>
  </Layout>
  <!-- <div v-if="isMobile()" class="col">
        <div class="shadow-sm p-4 bg-white rounded mb-4">
          <h5 class="card-title">Avg. resolution</h5>
          <clip-loader :loading="isLoading" color="#63c2de"></clip-loader>
          <h1 v-if="!isLoading" class="display-4 text-muted">{{ metrics.stats.resolution_time }}hrs</h1>
        </div>
      </div> -->
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Layout from "../components/Layout.vue";
import PageHeader from "../components/ui/PageHeader/PageHeader.vue";

import EventSeverityChart from "@/components/Dashboard/EventSeverityChart";
import EventSeverityPie from "@/components/Dashboard/EventSeverityPie";
import EventActivityChart from "@/components/Dashboard/EventActivityChart";
import MetricCard from "@/components/ui/Dashboard/MetricCard";
import ChartCard from "@/components/ui/Dashboard/ChartCard";
import { isMobileOnly } from "mobile-device-detect";

export default {
  name: "dashboard",
  components: {
    Layout,
    PageHeader,
    EventSeverityChart,
    EventActivityChart,
    EventSeverityPie,
    MetricCard,
    ChartCard,
  },
  data() {
    return {
      selected: "Month",
      isLoading: true,
      isRefreshing: false,
      metrics: [],
    };
  },
  computed: {
    ...mapGetters("user", ["hasFeatureEnabled"]),
    isMobileOnly: function () {
      if (isMobileOnly) {
        return true;
      }
    },
    showResolved() {
      return this.hasFeatureEnabled("incident_resolve");
    },
    openIncidents() {
      if (!this.isLoading) {
        return this.metrics.incidents.open;
      }
    },
    closedIncidents() {
      if (!this.isLoading) {
        return this.metrics.incidents.closed;
      }
    },
    totalIncidents() {
      if (!this.isLoading) {
        return this.metrics.incidents.total;
      }
    },
    ceRating() {
      if (!this.isLoading) {
        return this.metrics.stats.effectiveness;
      }
    },
    severityTrends() {
      if (!this.isLoading) {
        return this.metrics.stats.severity_trends;
      }
    },
    activityTrends() {
      if (!this.isLoading) {
        return this.metrics.stats.activity;
      }
    },
    severityCounts() {
      if (!this.isLoading) {
        return this.metrics.stats.severity;
      }
    },
    // getcolor1: function() {
    //   if (this.metrics.stats.resolution_time >= 4) {
    //     return "danger";
    //   } else if (this.metrics.stats.resolution_time >= 2) {
    //     return "warning";
    //   } else if (this.metrics.stats.resolution_time <= 1) {
    //     return "success";
    //   }
    // },
    // getcolor2: function() {
    //   if (this.metrics.stats.effectiveness >= 40) {
    //     return "danger";
    //   } else if (this.metrics.stats.effectiveness >= 60) {
    //     return "warning";
    //   } else {
    //     return "success";
    //   }
    // }
    mobileHeight: function () {
      if (this.isMobile()) {
        return 140;
      } else {
        return 185;
      }
    },
  },
  methods: {
    refreshData() {
      this.isRefreshing = true;
      this.getMetrics();
    },
    refreshStop() {
      this.isRefreshing = false;
      console.log("stopped");
    },
    async getMetrics() {
      try {
        let uri = process.env.VUE_APP_API_ROOT + "metrics/basic/";
        const response = await axios.get(uri);
        this.metrics = response.data;
        this.isLoading = false;

        if (this.isRefreshing) {
          // Spins the icon for at least 2 seconds,
          // in case of a really short response time.
          setTimeout(() => (this.isRefreshing = false), 2000);
        }
      } catch (e) {
        // handle the authentication error here
        this.isRefreshing = false;
      }
    },
  },
  created() {
    this.getMetrics();
  },
};
</script>