<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: ["activity"],
  mounted() {
    const datalive = [
      this.activity["12h"],
      this.activity["9h"],
      this.activity["6h"],
      this.activity["3h"],
      this.activity["1h"]
    ];
    const timestamps = ["-12h", "", "", "", "Now"];
    this.renderChart(
      {
        labels: timestamps,
        datasets: [
          {
            label: "Activity",
            backgroundColor: "rgba(255, 162, 235, 0)",
            borderColor: "rgb(54, 162, 235)",

            borderWidth: 2,
            data: datalive
          }
        ]
      },
      {
        tooltips: {
          enabled: false,
          intersect: true
        },
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              }
            }
          ],
          yAxes: [
            {
              display: false,
              ticks: {
                suggestedMin: -1,
                suggestedMax: 10
              }
            }
          ]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 0,
            hoverRadius: 0,
            hoverBorderWidth: 0
          }
        }
      }
    );
  }
};
</script>
