<script>
import { Doughnut } from 'vue-chartjs'

export default {
    extends: Doughnut,
    props: ['severity'],
    mounted() {
        this.renderChart({
            labels: ['Low', 'Medium', 'High', 'Extreme'],
            datasets: [
                {
                    backgroundColor: [
                        "rgb(16,185,129)",
                        "rgb(250,204,21)",
                        "rgb(249,115,22)",
                        "rgb(239,68,68)"
                        // "rgba(75, 192, 192, 0.2)",
                        // "rgba(255, 205, 86, 0.2)",
                        // "rgba(255, 159, 64, 0.2)",
                        // "rgba(255, 99, 132, 0.2)"
                    ],
                    borderColor: [
                        "rgb(75, 192, 192)",
                        "rgb(255, 205, 86)",
                        "rgb(255, 159, 64)",
                        "rgb(255, 99, 132)"
                    ],
                    borderWidth: 0,
                    data: [
                        this.severity.low, this.severity.medium, this.severity.high, this.severity.extreme]
                }
            ]
        },
            {
                responsive: true,
                maintainAspectRatio: false,
                cutoutPercentage: 60,
                legend: {
                    display: true,
                    position: 'left',
                    align: 'end',
                    labels: {
                        usePointStyle: true,
                        boxWidth: 7
                    }

                },
            }
        )
    }
}
</script>


