<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: ["severity_trends"],
  methods: {
    getBgColor() {
      if (this.severity_trends["1h"] === "extreme") {
        return  "rgba(255, 99, 132, 0.2)"
      } else if (this.severity_trends["1h"] === "high") {
        return "rgba(255, 159, 64, 0.2)"
      } else if (this.severity_trends["1h"] === "medium") {
        return  "rgba(255, 205, 86, 0.2)"
      } else {
        return "rgba(75, 192, 192, 0.2)"
      }
    },
    getBdColor() {
      if (this.severity_trends["1h"] === "extreme") {
        return "rgb(255, 99, 132)"
      } else if (this.severity_trends["1h"] === "high") {
        return "rgb(255, 159, 64)"
      } else if (this.severity_trends["1h"] === "medium") {
        return "rgb(255, 205, 86)"
      } else {
        return "rgb(75, 192, 192)"
      }
    }
  },
  mounted() {
      var n1 = this.severity_trends["12h"]
      var n2 = this.severity_trends["9h"]
      var n3 = this.severity_trends["6h"]
      var n4 = this.severity_trends["3h"]
      var n5 = this.severity_trends["1h"]

    if (!n1) n1 = ""
    if (!n2) n2 = ""
    if (!n3) n3 = ""
    if (!n4) n4 = ""
    if (!n5) n5 = ""

    const datalive = [
      n1, n2, n3, n4, n5
    ];
    const timestamps = ["-12h", "", "", "", "Now"];

    this.renderChart(
      {
        xLabels: timestamps,
        yLabels: ["extreme", "high", "medium", "low", "", "-1"],
        datasets: [
          {
            label: "Severity",
            backgroundColor: this.getBgColor(),
            borderColor: this.getBdColor(),
            borderWidth: 2,
            data: datalive
          }
        ]
      },
      {
        tooltips: {
          enabled: false,
          intersect: true
        },
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        responsive: true,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              type: "category",
              position: "left",
              display: false,
              ticks: {
                suggestedMin: -1
              }
            }
          ]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 0,
            hoverRadius: 0,
            hoverBorderWidth: 0
          },
          line: {
                // tension: 0.4// disables bezier curves
            }
        }
      }
    );
  }
};
</script>
