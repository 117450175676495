<template>
    <div class="tw-bg-white tw-overflow-hidden tw-shadow tw-rounded-lg">
        <div class="tw-px-4 tw-py-5 sm:tw-p-6">
            <dt class="tw-text-lg tw-font-light tw-text-gray-900">
                {{ label }}
            </dt>
        </div>
        <dd class="tw-p-2 tw-h-100 tw-w-100 tw-mt-1">
            <clip-loader :loading="loading" color="#63c2de"></clip-loader>
            <slot></slot>
        </dd>
    </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
    props: ["value", "label", "color", "loading"],
    components: {
        ClipLoader
    }
}
</script>

<style>

</style>